import { render, staticRenderFns } from "./cameraLiveRadio-detail.vue?vue&type=template&id=1ac8e038&scoped=true&"
import script from "./cameraLiveRadio-detail.vue?vue&type=script&lang=js&"
export * from "./cameraLiveRadio-detail.vue?vue&type=script&lang=js&"
import style0 from "./cameraLiveRadio-detail.vue?vue&type=style&index=0&id=1ac8e038&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ac8e038",
  null
  
)

export default component.exports