<template>
    <div class="cameraLiveRadio-list">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">增值服务</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">视频探宝</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline" :rules="ruleValidate">
            <el-form-item label="选择学校" prop="schoolId">
                <el-select v-model="queryModel.schoolId" filterable  size="mini" placeholder="请选择"  @change="selectTrigger">
                    <el-option
                    v-for="item in schoolList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择班级" prop="classId">
                <el-select v-model="queryModel.classId" filterable  size="mini" placeholder="请选择">
                    <el-option
                    v-for="item in classList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="handleQuery('queryForm')"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                    type="info"
                    size="mini"
                    style="margin-left: 8px"
                    @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>
            <el-divider></el-divider>
            <el-row class="button-group">
            <el-button
                type="primary"
                size="small"
                plain
                icon="el-icon-download"
                :loading="downloadLoading"
                @click="downloadXls"
            >导出数据</el-button>
            </el-row>
            <el-table
                ref="formTable"
                :data="tableData"
                v-loading="loading"
                element-loading-text="拼命加载中"
                stripe
            >
                <el-table-column prop="schoolName" label="学校名称" align="center"></el-table-column>
                <el-table-column prop="cameraName" label="房间名称" align="center"></el-table-column>
                <el-table-column prop="className" label="班级名称" align="center"></el-table-column>
                <el-table-column label="开播计划" align="center">
                    <el-table-column
                        prop="monRangeTime"
                        label="周一"
                        width="120"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="tueRangeTime"
                        label="周二"
                        width="120"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="wedRangeTime"
                        label="周三"
                        width="120"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="thurRangeTime"
                        label="周四"
                        width="120"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="friRangeTime"
                        label="周五"
                        width="120"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="satRangeTime"
                        label="周六"
                        width="120"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="sunRangeTime"
                        label="周日"
                        width="120"
                        align="center">
                    </el-table-column>
                </el-table-column>

                <el-table-column label="操作" fixed="right" width="150" align="center">
                    <template slot-scope="{row}">
                        <el-button size="mini" v-if="row.id" type="warning" @click="handleEdit(row)">管理</el-button>
                    </template>
                </el-table-column>
            </el-table>
            
        </el-form>
        <cameraLiveRadio-detail
            v-if="showModal"
            :businessKey="businessKey"
            :parentId="selectedRecord.id"
            :title="modalTitle"
            @close="onDetailModalClose"
        ></cameraLiveRadio-detail>
    </div>
</template>
<script>

import cameraLiveRadioApi from '@/api/business/cameraLiveRadioApi'
import cameraLiveRadioDetail from "./cameraLiveRadio-detail";
export default {
    name:"businessCameraLiveRadioList",
    data(){
        return{
            ruleValidate: {
                schoolId: [{ required: true, message: "学校不能为空", trigger: "blur" }],
            },
            queryModel: {
                schoolId:"",
                classId:"",
            },
            schoolList:[],
            classList:[],
            loading: false,
            tableData: [],
            showModal: false,
            downloadLoading: false,
        }
    },
    methods: {
        changePage() {
            this.loading = true;
            var formData = new FormData();

            formData.append("schoolId", this.queryModel.schoolId);
            formData.append("classId", this.queryModel.classId);

            cameraLiveRadioApi.list(formData).then((response)=>{
                this.loading = false;
                var jsonData = response.data;
  
                console.log(jsonData);

                this.tableData = jsonData.data;
            })
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        selectTrigger(val) {
            var schoolId = val;
            var formData = new FormData();
            formData.append("schoolId", schoolId);
            
            cameraLiveRadioApi.classList(formData).then(response => {
                var jsonData = response.data;
                this.classList = jsonData.data;
            });
        },
        handleEdit(record) {
            this.modalTitle = "管理";
            this.operation = "edit";
            this.businessKey = record.id;
            this.selectedRecord = record;
            this.showModal = true;
        },
        onDetailModalClose(retObj) {
            //保存成功后回调
            this.showModal = false;

            if (retObj.result) {
                var retData = retObj.data;

                if (this.operation == "edit") {
                    this.changePage();
                }
            }
        },
        handleQuery() {
            var self = this;

            this.$refs["queryForm"].validate(valid => {
                if (valid) {
                    this.changePage();
                }
            });
        },
        downloadXls() {
            //导出
            var self = this;

            var formData = new FormData();

            if(this.queryModel.schoolId!=null&&this.queryModel.schoolId!=""){

                self.downloadLoading = true;
                self.loading = true;
                self.loadingText = "数据导出中，请稍后...";

                formData.append("schoolId", this.queryModel.schoolId);
                formData.append("classId", this.queryModel.classId);
                formData.append("pageSize", 2000);

                cameraLiveRadioApi.exportXls(formData).then(function(response) { 
                    var jsonData = response.data;
                    self.downloadLoading = false;
                    self.loading = false;
                    if (jsonData.result) {
                    //下载有错误信息提示的报表
                    self.$message({
                        showClose: true,
                        dangerouslyUseHTMLString: true,
                        message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                        duration: 60000,
                        type: "success"
                    });
                    } else {
                    self.$message.error(jsonData.message);
                    }
                });
            }
            else{
                self.$message.error("请选择学校查询然后再导出！")
            }
        },


    },
    created() {
        var self = this;

        cameraLiveRadioApi.schoolList().then(response => {
            var jsonData = response.data;
            this.schoolList = jsonData.data;
        });
    },
    components: {
        "cameraLiveRadio-detail": cameraLiveRadioDetail
    },
}
</script>
<style scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.cameraLiveRadio-list{
    text-align: left;
}
</style>