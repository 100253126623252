<template>
    <el-dialog
        width="50%"
        :visible.sync="showDialog"
        :title="title"
        :modal-append-to-body="true"
        :append-to-body="true"
        style="text-align:left;"
        @close="closeDialog"
        :close-on-click-modal="false"
        >
        <div class="user-panel" v-loading="loading">
                <el-form ref="form" :model="formModel" :label-width="'80px'">
                  <el-input v-model="formModel.id" style="display:none"></el-input>
                    <el-form-item label="周一" prop="monRangeTime">
                        <el-time-picker
                            is-range
                            v-model="formModel.monRangeTime"
                            arrow-control
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围"
                            format='HH:mm'
                            value-format="HH:mm">
                        </el-time-picker>
                    </el-form-item>
                    <el-form-item label="周二" prop="tueRangeTime">
                        <el-time-picker
                            is-range
                            arrow-control
                            v-model="formModel.tueRangeTime"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围"
                            format='HH:mm'
                            value-format="HH:mm">
                        </el-time-picker>
                        &nbsp;
                        <el-button size="medium" @click="copyTime('monRangeTime','tueRangeTime')">同上</el-button>
                    </el-form-item>
                    <el-form-item label="周三" prop="wedRangeTime">
                        <el-time-picker
                            is-range
                            arrow-control
                            v-model="formModel.wedRangeTime"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围"
                            format='HH:mm'
                            value-format="HH:mm">
                        </el-time-picker>
                        &nbsp;
                        <el-button size="medium" @click="copyTime('tueRangeTime','wedRangeTime')">同上</el-button>
                    </el-form-item>
                    <el-form-item label="周四" prop="thurRangeTime">
                        <el-time-picker
                            is-range
                            arrow-control
                            v-model="formModel.thurRangeTime"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围"
                            format='HH:mm'
                            value-format="HH:mm">
                        </el-time-picker>
                        &nbsp;
                        <el-button size="medium" @click="copyTime('wedRangeTime','thurRangeTime')">同上</el-button>
                    </el-form-item>
                    <el-form-item label="周五" prop="friRangeTime">
                        <el-time-picker
                            is-range
                            arrow-control
                            v-model="formModel.friRangeTime"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围"
                            format='HH:mm'
                            value-format="HH:mm">
                        </el-time-picker>
                        &nbsp;
                        <el-button size="medium" @click="copyTime('thurRangeTime','friRangeTime')">同上</el-button>
                    </el-form-item>
                    <el-form-item label="周六" prop="satRangeTime">
                        <el-time-picker
                            is-range
                            arrow-control
                            v-model="formModel.satRangeTime"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围"
                            format='HH:mm'
                            value-format="HH:mm">
                        </el-time-picker>
                        &nbsp;
                        <el-button size="medium" @click="copyTime('friRangeTime','satRangeTime')">同上</el-button>
                    </el-form-item>
                    <el-form-item label="周日" prop="sunRangeTime">
                        <el-time-picker
                            is-range
                            arrow-control
                            v-model="formModel.sunRangeTime"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围"
                            format='HH:mm'
                            value-format="HH:mm">
                        </el-time-picker>
                        &nbsp;
                        <el-button size="medium" @click="copyTime('satRangeTime','sunRangeTime')">同上</el-button>
                    </el-form-item>
                </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import cameraLiveRadioApi from '@/api/business/cameraLiveRadioApi'
export default {
    props: ["businessKey", "title"],
    data() {
      return{
        formModel: {
          id: "",
          monRangeTime: null,
          tueRangeTime: null,
          wedRangeTime: null,
          thurRangeTime: null,
          friRangeTime: null,
          satRangeTime: null,
          sunRangeTime: null
        },
        showDialog: true,
        loading: false,
        submitting: false,
        showMapDialog: false,
        selectedRow: null,
      }
    },
    methods: {
      closeDialog() {
        this.$emit("close", false);
      },
      handleSubmit() {
        var self = this;
        this.$refs["form"].validate(valid => {
          if (valid) {
            (function() {
              var id = self.formModel.id;
              if(self.formModel.monRangeTime!=null&&self.formModel.monRangeTime!=""){
                var monRangeTime = self.formModel.monRangeTime.toString();
                self.formModel.monRangeTime = monRangeTime;
              }
              else{
                self.formModel.monRangeTime = "";
              }

              if(self.formModel.tueRangeTime!=null&&self.formModel.tueRangeTime!=""){
                var tueRangeTime = self.formModel.tueRangeTime.toString();
                self.formModel.tueRangeTime = tueRangeTime;
              }
              else{
                self.formModel.tueRangeTime = "";
              }

              if(self.formModel.wedRangeTime!=null&&self.formModel.wedRangeTime!=""){
                var wedRangeTime = self.formModel.wedRangeTime.toString();
                self.formModel.wedRangeTime = wedRangeTime;
              }
              else{
                self.formModel.wedRangeTime = "";
              }

              if(self.formModel.thurRangeTime!=null&&self.formModel.thurRangeTime!=""){
                var thurRangeTime = self.formModel.thurRangeTime.toString();
                self.formModel.thurRangeTime = thurRangeTime;
              }
              else{
                self.formModel.thurRangeTime = "";
              }

              if(self.formModel.friRangeTime!=null&&self.formModel.friRangeTime!=""){
                var friRangeTime = self.formModel.friRangeTime.toString();
                self.formModel.friRangeTime = friRangeTime;
              }
              else{
                self.formModel.friRangeTime = "";
              }

              if(self.formModel.satRangeTime!=null&&self.formModel.satRangeTime!=""){
                var satRangeTime = self.formModel.satRangeTime.toString();
                self.formModel.satRangeTime = satRangeTime;
              }
              else{
                self.formModel.satRangeTime = "";
              }

              
              if(self.formModel.sunRangeTime!=null&&self.formModel.sunRangeTime!=""){
                var sunRangeTime = self.formModel.sunRangeTime.toString();
                self.formModel.sunRangeTime = sunRangeTime;
              }
              else{
                self.formModel.sunRangeTime = "";
              }

              return cameraLiveRadioApi.update(self.formModel);
              
            })().then(function(response) {
              var jsonData = response.data;

              if (jsonData.result) {
                self.$message({
                  message: "保存成功!",
                  type: "success"
                });
                self.$emit("close", {
                  result: true,
                  data: jsonData.data
                });
              } else {
                self.$message({
                  message: jsonData.message + "",
                  type: "warning"
                });

                self.$emit("close", {
                  result: false
                });
              }
            });
          }
        });
      },
      copyTime(fromTime,toTime){
        this.formModel[toTime] = [this.formModel[fromTime][0],this.formModel[fromTime][1]];
      }
    },
    async mounted() {
      console.log("mounted");

      var self = this;

      (function() {
            return cameraLiveRadioApi.edit(self.businessKey);
        })()
        .then(response => {
            var jsonData = response.data;

            if (jsonData.result) {
                var dataList = jsonData.data.list;
                this.formModel.id = jsonData.data.id;

                console.log(dataList);

                dataList.forEach(item => {
                    var rangeTime = [];

                    rangeTime.push(item.startTime!=null ? item.startTime : '');
                    rangeTime.push(item.endTime!=null ? item.endTime : '');

                    console.log(rangeTime);
                     
                    if(item.week=="1"){
                        this.formModel.monRangeTime = rangeTime;
                    }
                    else if(item.week=="2"){
                        this.formModel.tueRangeTime = rangeTime;
                    }
                    else if(item.week=="3"){
                        this.formModel.wedRangeTime = rangeTime;
                    }
                    else if(item.week=="4"){
                        this.formModel.thurRangeTime = rangeTime;
                    }
                    else if(item.week=="5"){
                        this.formModel.friRangeTime = rangeTime;
                    }
                    else if(item.week=="6"){
                        this.formModel.satRangeTime = rangeTime;
                    }
                    else {
                        this.formModel.sunRangeTime = rangeTime;
                    }
                });
            } else {
              self.$message.error(jsonData.message + "");
            }
        })
        .catch(error => {
            self.$message.error(error + "");
        });

    }
}
</script>
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>